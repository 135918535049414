<template>
  <div class="goods-detailed">
    <div class="breadcrumb">
      <span>首页</span>
      <img src="@/assets/images/icon-right-grey.png" alt="" />
      <span>全部游戏</span>
      <img src="@/assets/images/icon-right-grey.png" alt="" />
      <span>{{ gameName }}</span>
      <img src="@/assets/images/icon-right-grey.png" alt="" />
      <span>订单详情</span>
    </div>
    <div class="detailed">
      <div class="left">
        <div class="pic">
          <img :src="goodsImg" alt="" />
        </div>
        <div class="collect" @click="collectClick">
          <img src="@/assets/images/collect-1.png" alt="" v-if="goodsInfo.is_collect == 0" />
          <img src="@/assets/images/collect-2.png" alt="" v-else />
          <text>收藏</text>
        </div>
      </div>
      <div class="right">
        <div class="right-head" v-if="goodsInfo.admin_id > 0">
          <text>官方发布</text>
          <text style="margin-left: 5px">{{ goodsInfo.title }}</text>
        </div>
        <div class="right-head user-publishing" v-else>
          <text>用户发布</text>
          <text style="margin-left: 5px">{{ goodsInfo.title }}</text>
        </div>
        <div class="right-price">
          <text>商品价格：</text>
          <text>¥</text>
          <text>{{ goodsInfo.price / 100 }}</text>
        </div>
        <div class="right-info">
          <div class="right-info-item">
            <text>账号类型：</text>
            <text>账号</text>
          </div>
          <div class="right-info-item">
            <text>{{ goodsName }}：</text>
            <text>{{ goodsInfo.name }}-{{ paramValue }}</text>
          </div>
          <div class="right-info-item">
            <text>订单编号：</text>
            <text>{{ orderInfo.order_no }}</text>
          </div>
          <div class="right-info-item">
            <text>上架时间：</text>
            <text>{{ formatDate(goodsInfo.shelf_time) }}</text>
          </div>
          <div
            class="right-info-item"
            v-if="goodsInfo.is_top === 1 || goodsInfo.is_compensate === 1"
          >
            <text>服务保障：</text>
            <text v-if="goodsInfo.is_top === 1">顶级账号 ·</text>
            <text v-if="goodsInfo.is_compensate === 1">支持包赔</text>
          </div>
          <countDown
            v-if="orderInfo.type === 2 && goodsInfo.end_time"
            :endTime="goodsInfo.end_time"
          ></countDown>
        </div>
        <div class="right-btn">
          <!-- <div class="bargaining" @click="bargainingClick" v-if="type === 'buy'">
            <img src="@/assets/images/bargaining-icon.png" alt="" />
            <text>砍价购买</text>
          </div>
          <div class="shop" @click="shoppingClick('立即购买')" v-if="type === 'buy'">
            <img src="@/assets/images/shop-icon.png" alt="" />
            <text>立即购买</text>
          </div>
          <div
            class="bargaining"
            @click="shoppingClick('立即报名')"
            v-if="type === 'partBidding' && goodsInfo.is_join === 0"
            style="margin-right: 16px"
          >
            <img src="@/assets/images/bidding-icon.png" alt="" />
            <text>立即报名</text>
          </div>
          <div
            class="quotation"
            @click="quotationClick"
            v-if="type === 'partBidding' && goodsInfo.is_join === 1"
          >
            <img src="@/assets/images/deta-icon.png" alt="" />
            <text>我要报价</text>
          </div> -->
          <p class="receive" @click="receive" v-if="orderInfo.status === 2">确认收货</p>
          <p @click="mark(orderInfo)" v-if="orderInfo.status === 7" class="receive">立即加价</p>
          <p class="receive" @click="buyClick(orderInfo)" v-if="orderInfo.status === 0">立即支付</p>
          <p class="employ" @click="cancel" v-if="orderInfo.status === 0">取消订单</p>
          <p class="employ" v-if="orderInfo.status === 3 || orderInfo.status === 4">申请售后</p>
          <div class="service" @click="serviceClick">
            <img src="@/assets/images/service-icon2.png" alt="" />
            <text>联系客服</text>
          </div>
        </div>
      </div>
    </div>
    <div class="introduce">
      <div class="introduce-head">
        <p :class="current == 0 ? 'active' : ''" @click="changeIndex(0)">商品详情</p>
        <p :class="current == 1 ? 'active' : ''" @click="changeIndex(1)">购买流程</p>
      </div>
      <div class="introduce-content" v-if="current == 0">
        <div class="introduce-content-info">
          <p v-for="(item, index) in goodsInfo.params" :key="index">
            <text>{{ item.name }}：</text>
            <text>{{ item.param_value }}</text>
          </p>
        </div>
        <div class="text" ref="introduceText" :class="moreDevelop == false ? 'develop' : ''">
          {{ goodsInfo.introduction }}
        </div>
        <div class="more-btn" @click="moreClick" v-if="moreBtn">
          <text>{{ moreText }}</text>
          <img :src="moreImg" alt="" />
        </div>
      </div>
      <div v-if="current == 1" class="purchase-process" v-html="articleHtml"></div>
    </div>
    <div class="index-common-title">
      <div class="line"></div>
      <p>游戏截图</p>
    </div>
    <div class="cre-pic">
      <img v-for="(item, index) in goodsInfo.description" :key="index" :src="item" alt="" />
    </div>
    <cancelOrder
      :type="dialogObj.dialogType"
      :dialogVisible="dialogObj.show"
      :orderId="dialogObj.orderId"
      @clickChild="clickChild"
    ></cancelOrder>
    <biddingModal ref="biddingVisible" :goodsInfo="orderInfo" @clickChild="clickChild" />
    <payDialog
      :dialogVisible="payment.show"
      :orderNo="payment.orderNo"
      :price="payment.price"
      :balance="payment.balance"
      @clickChild="clickChild"
    ></payDialog>
  </div>
</template>

<script setup>
import countDown from './components/count-down.vue'
import cancelOrder from '@/views/User/components/cancelOrder.vue'
import biddingModal from '@/views/Goods/components/bidding-modal.vue'
import payDialog from '@/components/payDialog/index.vue'
import { ref, onMounted, reactive, nextTick } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { findGoods, setCollect, article, balancePay } from '@/api/goods'
import { afterSales, orderDetail } from '@/api/order'
import { message } from 'ant-design-vue'
import store from '@/store'
import { formatDate } from '@/utils/formatDate.js'
import { ElMessage } from 'element-plus'
import { getChatService } from '@/api/service'
const router = useRouter()
const route = useRoute()
const current = ref(0)
const moreImg = ref(require('@/assets/images/icon-down.png'))
const moreText = ref('详情介绍')
const moreDevelop = ref(true)
const moreBtn = ref(false)
const introduceText = ref(null)
const biddingVisible = ref(null)
const dialogObj = reactive({
  show: false,
  orderId: 0,
  dialogType: 0
})
const payment = reactive({
  show: false,
  orderNo: '',
  price: 0,
  balance: 0
})
const gameName = ref('')
const shopTitle = ref('')
const goodsInfo = ref({})
const goodsImg = ref('')
const goodsName = ref('')
const paramValue = ref('')
const orderInfo = ref({})
function changeIndex(index) {
  current.value = index
}
function moreClick() {
  moreDevelop.value = !moreDevelop.value
  if (moreDevelop.value === false) {
    moreImg.value = require('@/assets/images/icon-upout.png')
  } else {
    moreImg.value = require('@/assets/images/icon-down.png')
  }
}
const serviceClick = () => {
  getChatService({ type: 0, game_id: goodsInfo.value.game_id }).then((res) => {
    if (res.code == 0) {
      let to = router.resolve({
        path: '/service',
        query: { account: res.data.id, goodsid: goodsInfo.value.id }
      })
      window.open(to.href, '_blank')
    }
  })
}
const gooodsDeatil = () => {
  findGoods(route.query.goodsId).then((res) => {
    if (res.code == 200) {
      goodsImg.value = res.data.image[0]
      goodsName.value = res.data.params[0].name
      paramValue.value = res.data.params[0].param_value
      gameName.value = res.data.name
      nextTick(() => {
        goodsInfo.value = res.data
      })
    }
  })
}
const order = () => {
  orderDetail({ order_id: route.query.orderId }).then((res) => {
    if (res.code == 200) {
      orderInfo.value = res.data
    }
  })
}
function collectClick() {
  setCollect({ goods_id: goodsId.value }).then((res) => {
    if (res.code == 200) {
      message.success(res.msg)
      gooodsDeatil()
    } else {
      message.error(res.msg)
    }
  })
}

const articleHtml = ref('')
const getArticle = () => {
  article({ id: 11 }).then((res) => {
    if (res.code == 200) {
      articleHtml.value = res.data.pc_content
    }
  })
}
getArticle()
//立即支付
const buyClick = (i) => {
  console.log(i)
  const user = JSON.parse(localStorage.getItem('userInfo'))
  payment.show = true
  payment.orderNo = i.order_no
  payment.price = i.price
  payment.balance = user.balance
}
//收货
const receive = () => {
  dialogObj.show = true
  dialogObj.orderId = route.query.orderId
  dialogObj.dialogType = 1
}
const cancel = () => {
  dialogObj.show = true
  dialogObj.orderId = route.query.orderId
  dialogObj.dialogType = 0
  gooodsDeatil()
}
const clickChild = (e) => {
  dialogObj.show = e
  payment.show = e
  gooodsDeatil()
  order()
}
//加价
const mark = () => {
  biddingVisible.value.visible = true
}
onMounted(() => {
  nextTick(() => {
    gooodsDeatil()
    order()
  })
  if (introduceText.value.clientHeight < introduceText.value.scrollHeight) {
    moreBtn.value = true
  }
})
</script>

<style scoped lang="less">
.goods-detailed {
  margin: 0 360px;
  .breadcrumb {
    margin: 14px 0;
    span {
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #666666;
      cursor: pointer;
    }
    img {
      margin: 0 3px;
    }
    span:last-child {
      font-size: 14px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #333333;
      cursor: auto;
    }
  }
  .detailed {
    display: flex;
    width: 1200px;
    background: #ffffff;
    border-radius: 12px;
    padding: 18px;
    .left {
      margin-right: 32px;
      .pic {
        width: 563px;
        height: 357px;
        border-radius: 8px;
        img {
          width: 563px;
          height: 357px;
          border-radius: 8px;
        }
      }
      .collect {
        margin-top: 16px;
        cursor: pointer;
        text {
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #ffb552;
          margin-left: 5px;
        }
      }
    }
    .right {
      .right-head {
        text:nth-child(1) {
          display: inline-block;
          width: 62px;
          padding: 2px 0;
          text-align: center;
          font-size: 13px;
          font-family: YouSheBiaoTiHei;
          color: #ffffff;
          background: linear-gradient(135deg, #058aff 0%, #0dbaff 100%);
          border-radius: 5px;
        }
        text:nth-child(2) {
          font-size: 20px;
          font-family: PingFangSC-Semibold, PingFang SC;
          font-weight: 600;
          color: #333333;
        }
      }
      .user-publishing {
        text:nth-child(1) {
          display: inline-block;
          width: 62px;
          padding: 2px 0;
          text-align: center;
          font-size: 13px;
          font-family: YouSheBiaoTiHei;
          color: #66410e;
          background: linear-gradient(135deg, #e1ba80 0%, #f2e4b3 100%);
          border-radius: 5px;
        }
      }
      .right-price {
        width: 569px;
        height: 80px;
        background: #f5f7fb;
        border-radius: 8px;
        margin-top: 16px;
        display: flex;
        align-items: center;
        padding-left: 16px;
        text:nth-child(1) {
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #999999;
        }
        text:nth-child(2) {
          font-size: 16px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #ff211b;
        }
        text:nth-child(3) {
          font-size: 38px;
          font-family: PingFangSC-Semibold, PingFang SC;
          font-weight: 600;
          color: #ff211b;
        }
      }
      .right-info {
        .right-info-item {
          margin-top: 16px;
          text:nth-child(1) {
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #999999;
          }
          text:nth-child(2) {
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #333333;
          }
          .time_day {
            display: inline-block;
            width: 20px;
            height: 20px;
            background: #0cb6ff;
            border-radius: 4px;
            font-size: 14px;
            font-family: DIN-BoldItalic, DIN;
            font-weight: normal;
            color: #ffffff;
            text-align: center;
            line-height: 20px;
          }
          .time_text {
            margin: 0 5px;
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #333333;
          }
        }
      }
      .right-btn {
        display: flex;
        margin-top: 20px;
        justify-content: flex-end;
        .bargaining {
          width: 140px;
          height: 40px;
          text-align: center;
          line-height: 40px;
          background: linear-gradient(135deg, #ffa944 0%, #fe7600 100%);
          border-radius: 8px;
          cursor: pointer;
          text {
            font-size: 16px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #ffffff;
            margin-left: 5px;
          }
        }
        .quotation {
          width: 140px;
          height: 40px;
          text-align: center;
          line-height: 40px;
          background: linear-gradient(135deg, #058aff 0%, #0dbaff 100%);
          border-radius: 8px;
          cursor: pointer;
          margin-right: 16px;
          text {
            font-size: 16px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #ffffff;
            margin-left: 5px;
          }
        }
        .shop {
          width: 140px;
          height: 40px;
          text-align: center;
          line-height: 40px;
          background: linear-gradient(135deg, #058aff 0%, #0dbaff 100%);
          border-radius: 8px;
          margin: 0 16px;
          cursor: pointer;
          text {
            font-size: 16px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #ffffff;
            margin-left: 5px;
          }
        }
        .service {
          width: 140px;
          height: 40px;
          text-align: center;
          line-height: 40px;
          background: linear-gradient(90deg, #eff8ff 0%, #eff8ff 100%);
          border-radius: 8px;
          margin-left: 10px;
          cursor: pointer;
          text {
            font-size: 16px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #06a4f9;
            margin-left: 5px;
          }
        }
        .receive {
          width: 140px;
          height: 40px;
          text-align: center;
          line-height: 40px;
          background: linear-gradient(135deg, #058aff 0%, #0dbaff 100%);
          font-size: 16px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #ffffff;
          border-radius: 8px;
          cursor: pointer;
        }
        .employ {
          width: 140px;
          height: 40px;
          text-align: center;
          line-height: 40px;
          font-size: 16px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #999999;
          border-radius: 8px;
          border: 1px solid #999999;
          margin-left: 16px;
          cursor: pointer;
        }
      }
    }
  }
  .introduce {
    margin-top: 20px;
    .introduce-head {
      width: 1200px;
      height: 45px;
      background: #eeeeee;
      border-radius: 12px 12px 0px 0px;
      display: flex;
      p {
        width: 120px;
        height: 45px;
        text-align: center;
        line-height: 45px;
        font-size: 18px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #666666;
        cursor: pointer;
      }
      .active {
        background: linear-gradient(135deg, #058aff 0%, #0dbaff 100%);
        color: #ffffff;
        border-radius: 12px 12px 0px 0px;
      }
    }
    .introduce-content {
      width: 1200px;
      background: #ffffff;
      padding: 24px 20px;
      border-radius: 0 0 12px 12px;
      .introduce-content-info {
        p {
          display: inline-block;
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #333333;
        }
        p:not(:first-child) {
          margin-left: 20px;
        }
      }
      .text {
        height: 40px;
        overflow: hidden;
        margin-top: 20px;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #333333;
      }
      .develop {
        height: auto;
      }
      .more-btn {
        width: 94px;
        height: 28px;
        border-radius: 4px;
        border: 1px solid #d9d9d9;
        text-align: center;
        line-height: 28px;
        margin: 0 auto;
        cursor: pointer;
        margin-top: 20px;
        text {
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #666666;
        }
        img {
          margin-left: 4px;
        }
      }
    }
    .purchase-process {
      padding: 24px 20px;
      width: 1200px;
      background: #ffffff;
      border-radius: 0 0 12px 12px;
    }
  }
  .index-common-title {
    display: flex;
    margin-top: 20px;
    .line {
      width: 6px;
      height: 19px;
      background: linear-gradient(135deg, #058aff 0%, #0dbaff 100%);
      border-radius: 3px;
      margin-top: 3px;
    }
    p {
      font-size: 18px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #333333;
      margin-left: 8px;
    }
  }
  .cre-pic {
    img {
      width: 1200px;
      height: 599px;
      margin-top: 20px;
      border-radius: 12px;
    }
  }
}
</style>
