<template>
  <div class="right-info-item">
    <text>竞价计时：</text>
    <span class="time_day">{{ state.day }}</span>
    <span class="time_text">天</span>
    <span class="time_day">{{ state.hour }}</span>
    <span class="time_text">时</span>
    <span class="time_day">{{ state.min }}</span>
    <span class="time_text">分</span>
    <span class="time_day">{{ state.sec }}</span>
    <span class="time_text">秒</span>
  </div>
</template>
<script setup>
import { ref, reactive, watch, onMounted } from 'vue'
const props = defineProps({
  endTime: Number
})

const state = reactive({
  day: '-',
  hour: '-',
  min: '-',
  sec: '-'
})

watch(props.endTime, (n, o) => {
  countdowm(n)
})

onMounted(() => {
  countdowm(props.endTime)
})

const countdowm = (timestamp) => {
  let timer = setInterval(function () {
    let nowTime = new Date()
    let endTime = new Date(timestamp * 1000)
    let t = endTime.getTime() - nowTime.getTime()
    if (t > 0) {
      let day = Math.floor(t / 86400000)
      let hour = Math.floor((t / 3600000) % 24)
      let min = Math.floor((t / 60000) % 60)
      let sec = Math.floor((t / 1000) % 60)
      hour = hour < 10 ? '0' + hour : hour
      min = min < 10 ? '0' + min : min
      sec = sec < 10 ? '0' + sec : sec
      state.day = day
      state.hour = hour
      state.min = min
      state.sec = sec
    } else {
      clearInterval(timer)
    }
  }, 1000)
}
</script>
<style lang="less" scoped>
.right-info-item {
  margin-top: 16px;
  text:nth-child(1) {
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #999999;
  }
  text:nth-child(2) {
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #333333;
  }
  .time_day {
    display: inline-block;
    width: 20px;
    height: 20px;
    background: #0cb6ff;
    border-radius: 4px;
    font-size: 14px;
    font-family: DIN-BoldItalic, DIN;
    font-weight: normal;
    color: #ffffff;
    text-align: center;
    line-height: 20px;
  }
  .time_text {
    margin: 0 5px;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #333333;
  }
}
</style>
